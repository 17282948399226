import { useEffect, useMemo, useState } from 'react'
import { analytics } from '../../../analytics/Analytics';

interface Options {
    sourceUrl?: string;
    channelName?: string;
    protocol?: string;
}

interface Response {
    result: {
        url: string
        sourceHistory: string[]
        sourcePlayMode: 'LIVE'
    }
    playlists: {
        dash: string
        hls: string
    }
}

const DEFAULT_URL = 'https://live.streaming.tivio.studio/live/ol24ae7lQ4ES1PN6UYSk/tRRu9BMn3eTTX22iTyLz/index.mpd';
const DEFAULT_MANAGEMENT_URL = 'https://management.tivio.studio/live/fm8415v3a64bfTCZ0Ti3/qWEPQQBdhgIveDSk4V47/user/3yjt38jTfupasgEvVhgd';

const channelNameToId = {
    'sport1': 'eV0yswdHcCFbcxw6KFct',
    'sport2': '98IcNE6GgyDyV29F6rW5',
    'joj': 'LYyAwEjjqmj8kMY23Lqw',
    'plus': '60K9GwR6CLApIHVyNYOj',
}

const getUrlFromQueryParams = (param: string) => {
    if (typeof window === 'undefined') return undefined;
    const params = new URLSearchParams(window.location.search);
    return params.get(param) || undefined;
}

const getSource = async (method: RequestInit["method"] = 'GET', payload?: any, abortController?: AbortController) => {
    const managementUrl = getUrlFromQueryParams('managementUrl') || DEFAULT_MANAGEMENT_URL;
    
    const resultJSON = await fetch(managementUrl, {
        ...(abortController ? { signal: abortController.signal } : {}),
        method,
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })

    const data: Response = await resultJSON.json()
    return data
}

export const useSource = (options: Options) => {
    const { sourceUrl, channelName, protocol } = options
    const [localSourceUrl, setLocalSourceUrl] = useState<string | undefined>(sourceUrl)
    const [url, setUrl] = useState<string | undefined>(sourceUrl?.startsWith('http') ? sourceUrl : undefined)
    const [isLoading, setIsLoading] = useState(false)

    const channelId = useMemo(() => channelName ? channelNameToId[channelName as keyof typeof channelNameToId] : undefined, [channelName])

    const getSourceUrlFromCloud = () => setLocalSourceUrl(undefined)

    const changeActiveVideo = async (activeVideoId: string) => {
        setIsLoading(true)
        const data = await getSource('PATCH', {
            "metadata": {
                "activeVideoId": activeVideoId,
            }
        })
        setUrl(data.playlists.hls)
        setLocalSourceUrl(data.playlists.hls)
        setIsLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()

        const init = async () => {
            try {
                setIsLoading(true)
                const queryUrl = getUrlFromQueryParams('url');
                const finalUrl = queryUrl || DEFAULT_URL;
                setUrl(finalUrl)
                setLocalSourceUrl(finalUrl)
                setIsLoading(false)
            } catch (err) {
                console.error('error fetching url', err)
                analytics.reportError({
                    ...(err as Error),
                    message: (err as Error).message ?? 'fetch failed',
                    name: (err as Error).name ?? 'useSource',
                })
            }
        }

        init()
        // if (!localSourceUrl) {
        //     if (!channelId) {
        //         console.error('Channel ID not source URL is set')
        //         analytics.reportError({
        //             message: 'Channel ID not source URL is set',
        //             name: 'useSource',
        //         })
        //         return
        //     }
        //     init()
        // }

        return () => {
            abortController.abort()
        }
    }, [channelId, localSourceUrl, protocol])

    return {
        url,
        isLoading,
        getSourceUrlFromCloud,
        changeActiveVideo
    }
}
